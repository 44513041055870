'use client'

import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { Button } from "@/components/ui/button"
import { DigitalRain } from '@/components/common/digital_rain/DigitalRain_NotFound'

export default function NotFound() {
  const [isJumping, setIsJumping] = useState(false)

  const handleJump = () => {
    setIsJumping(true)
    setTimeout(() => setIsJumping(false), 500)
  }

  const handleButtonClick = () => {
    handleJump()
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-green-100 flex flex-col items-center justify-center p-4 overflow-hidden font-sans relative">
      <div className="fixed inset-0 z-10 pointer-events-none">
        <DigitalRain />
      </div>
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="max-w-md w-full bg-white rounded-3xl shadow-xl overflow-hidden border-8 border-green-200 p-8 relative z-20"
      >
        <motion.div
          animate={isJumping ? { y: [0, -30, 0] } : { rotate: [0, -3, 3, -3, 0] }}
          transition={isJumping ? { duration: 0.5 } : { repeat: Infinity, duration: 2 }}
          className="flex justify-center mb-6"
        >
          <Image
            src="/branding/logo/platypus.png"
            alt="Cute platypus holding a football"
            width={200}
            height={200}
            priority
          />
        </motion.div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-4xl font-bold text-center text-gray-800 mb-4"
        >
          FUMBLE! 404 🏈
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="text-center text-gray-600 mb-6"
        >
          Our platypus friend fumbled the ball! This page is out of bounds.
          <br />
          Click anywhere to see the platypus jump!
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
          className="flex justify-center"
        >
          <Link href="/" onClick={(e) => e.stopPropagation()}>
            <Button
              className="bg-green-400 hover:bg-green-500 text-white font-bold py-3 px-6 rounded-full text-lg transition-all duration-300 ease-in-out transform hover:scale-105"
              onClick={handleButtonClick}
            >
              🏠 Back to Home Field
            </Button>
          </Link>
        </motion.div>
      </motion.div>
    </div>
  )
}
