'use client';

import { useEffect, useRef } from 'react';

export const DigitalRain = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const images = [
      '/branding/logo/platypus.png'
    ];

    const symbols: { x: number; y: number; speed: number }[] = [];
    const symbolCount = 50;
    let loadedImages: HTMLImageElement[] = [];

    // Initialize symbols
    for (let i = 0; i < symbolCount; i++) {
      symbols.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        speed: 1 + Math.random() * 3
      });
    }

    // Load images and start animation
    const init = async () => {
      loadedImages = await Promise.all(
        images.map(src => {
          const img = new Image();
          img.src = src;
          return new Promise<HTMLImageElement>((resolve) => {
            img.onload = () => resolve(img);
          });
        })
      );

      animate();
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      symbols.forEach(symbol => {
        // Increased opacity for better visibility on light background
        ctx.globalAlpha = 0.7;
        ctx.drawImage(loadedImages[0], symbol.x, symbol.y, 20, 20);
        symbol.y += symbol.speed * 1.5;

        if (symbol.y > canvas.height) {
          symbol.y = -30;
          symbol.x = Math.random() * canvas.width;
        }
      });

      requestAnimationFrame(animate);
    };

    init();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <canvas ref={canvasRef} className="fixed inset-0 pointer-events-none" />;
};
